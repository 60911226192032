// analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
    ReactGA.initialize(process.env.REACT_APP_MEASURE_ID, {
        gaOptions: {
            storage: 'none',
            storeGac: false,
            cookieFlags: 'SameSite=None; Secure',
        }
    });
};

export const trackPageview = (path) => {
    ReactGA.send({ hitType: 'pageview', page: path });
};
