import { Navigate } from "react-router-dom";
import { lazy } from 'react';
const Verification = lazy(() => import("../pages/Verification/Verification"));
const CheckUser = lazy(() => import("../pages/CheckUser/CheckUser"));
const OTP = lazy(() => import("../pages/OTP/OTP"));
const Home = lazy(() => import("../pages/Home/Home"));
const YourCrops = lazy(() => import("../pages/YourCrops"));
const CropDetails = lazy(() => import("../pages/CropDetails/CropDetails"));
const CropDetailsSolution = lazy(() => import("../pages/CropDetailsSolution/CropDetailsSolution"));
const PersonalInfo = lazy(() => import("../pages/PersonalInfo"));
const Subscription = lazy(() => import("../pages/Subscription/Subscription"));
const ConfirmSubscription = lazy(() => import("../pages/ConfirmSubscription/ConfirmSubscription"));
const Videos = lazy(() => import("../pages/Videos/Videos"));
const VideoPlayer = lazy(() => import("../pages/VideoPlayer"));
const MandiRates = lazy(() => import("../pages/MandiRates"));
const Market = lazy(() => import("../pages/MarketPlace"));
const Offers = lazy(() => import("../pages/MarketPlace/pages/Offers"));
const TodayOffers = lazy(() => import("../pages/MarketPlace/pages/TodayOffers"));
const MarketCategory = lazy(() => import("../pages/MarketPlace/pages/Category"));
const MarketBrand = lazy(() => import("../pages/MarketPlace/pages/Brand"));
const SuggestedProducts = lazy(() => import("../pages/MarketPlace/pages/SuggestedProducts"));
const BestSelling = lazy(() => import("../pages/MarketPlace/pages/BestSelling"));
const ProductPage = lazy(() => import("../pages/MarketPlace/pages/ProductPage"));
const MyCart = lazy(() => import("../pages/MarketPlace/pages/MyCart"));
const Checkout = lazy(() => import("../pages/MarketPlace/pages/Checkout"));
const OrderPlaced = lazy(() => import("../pages/MarketPlace/pages/OrderPlaced"));
const MyOrders = lazy(() => import("../pages/MarketPlace/pages/MyOrders"));
const OrderDetails = lazy(() => import("../pages/MarketPlace/pages/OrderDetails"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));

export const publicRoutes = [
    {
        path: "/",
        elem: <Navigate to="/home" />,
    },
    {
        path: "/authentication",
        elem: <Verification />,
    },
    {
        path: "/verification",
        elem: <CheckUser />,
    },
    {
        path: "/verification/otp-process",
        elem: <OTP />,
    },
];

export const privateRoutes = [
    {
      path: "/home",
      elem: <Home />,
    },
    // ================================= | Crop Routes | =================================
    {
      path: "/your-crops",
      elem: <YourCrops />,
    },
    {
      path: "/crop-details/:id",
      elem: <CropDetails />,
    },
    {
      path: "/crop-details/solution/:id",
      elem: <CropDetailsSolution />,
    },
    // ================================= | Profile Route | =================================
    {
      path: "/personal-info",
      elem: <PersonalInfo />,
    },
    // ================================= | Subscription Routes | =================================
    {
      path: "/subscription",
      elem: <Subscription />,
    },
    {
      path: "/confirm-subscription",
      elem: <ConfirmSubscription />,
    },
    // ================================= | Videos Routes | =================================
    {
      path: "/videos",
      elem: <Videos />,
    },
    {
      path: "/video-player",
      elem: <VideoPlayer />,
    },
    // ================================= | Mandi Route | =================================
    {
      path: "/mandi-rates",
      elem: <MandiRates />,
    },
    // ================================= | Market Place Routes | =================================
    {
      path: "/market",
      elem: <Market />,
    },
    {
      path: "/market/offers",
      elem: <Offers />,
    },
    {
      path: "/market/today-offers",
      elem: <TodayOffers />,
    },
    {
      path: "/market/category/:category",
      elem: <MarketCategory />,
    },
    {
      path: "/market/brand/:brand",
      elem: <MarketBrand />,
    },
    {
      path: "/market/suggested-products",
      elem: <SuggestedProducts />,
    },
    {
      path: "/market/best-selling",
      elem: <BestSelling />,
    },
    {
      path: "/market/product/:productName",
      elem: <ProductPage />,
    },
    {
      path: "/market/cart",
      elem: <MyCart />,
    },
    {
      path: "/market/checkout",
      elem: <Checkout />,
    },
    {
      path: "/market/order-placed/:orderId",
      elem: <OrderPlaced />,
    },
    {
      path: "/market/my-orders",
      elem: <MyOrders />,
    },
    {
      path: "/market/order-details/:orderId",
      elem: <OrderDetails />,
    },
    // ================================= | Error Route | =================================
    {
      path: "/error-page",
      elem: <ErrorPage />,
    }
  ];