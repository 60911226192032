import React, { createContext, useState, useContext, useLayoutEffect } from 'react';
import { API, authentication, subscribeUser } from '../API';
import { useDispatch } from 'react-redux';
import { getSubscriberDetails, setSubcriberData } from '../pages/Subscription/store';
import { addMsisdn } from '../redux/auth';
import SplashScreen from '../components/SplashScreen';
import subscriber from '../utils/subscriber.json'

const AuthContext = createContext();
const SPLASH_TIMEOUT = 2200;

const setAuthToken = (token) => {
    sessionStorage.setItem('authToken', token);
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const clearAuth = () => {
    sessionStorage.removeItem("authToken");
    localStorage.removeItem("persist:root");
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const handleSubscriberError = async (msisdn) => {
        try {
            const data = { subMode: "WEBVIEW", msisdn };
            await subscribeUser(data);
            const subRes = await dispatch(getSubscriberDetails({ cellno: msisdn })).unwrap();
            return subRes;
        } catch (error) {
            clearAuth();
            throw error;
        }
    };

    // const loadingState: setLoading = (loading) => setLoading(loading)

    // const timeout = 2200;

    const checkSubscriber = async (data) => {
        try {
            if (data) {
                const { msisdn, accessToken } = data;
                dispatch(addMsisdn({ msisdn }));
                setAuthToken(accessToken);

                const subRes = await dispatch(getSubscriberDetails({ cellno: msisdn })).unwrap();
                if (subRes.message === "Subscriber not found.") {
                    await handleSubscriberError(msisdn);
                }
            } else {
                const res = await authentication("", process.env.REACT_APP_DUMMY_NUMBER);
                const { msisdn, accessToken } = res.data.data;

                dispatch(addMsisdn({ msisdn }));
                dispatch(setSubcriberData(subscriber));
                setAuthToken(accessToken);
            }
        } catch (error) {
            console.error("Subscriber check failed:", error);
        } finally {
            setTimeout(() => setLoading(false), SPLASH_TIMEOUT);
        }
    };

    const parseURL = () => {
        const url = new URL(window.location.href);
        return {
            payload: url.searchParams.get('tp_payload'),
            cellNo: url.searchParams.get('msisdn'),
            token: url.searchParams.get('tpToken')
        };
    };

    useLayoutEffect(() => {
        const init = async () => {
            try {
                const authToken = sessionStorage.getItem('authToken');
                if (authToken) {
                    setAuthToken(authToken);
                    setTimeout(() => setLoading(false), SPLASH_TIMEOUT);
                    return;
                }

                const { payload, cellNo, token } = parseURL();

                if (!payload) {
                    if (!cellNo || !token || cellNo === "no" || token === "no") {
                        if (!cellNo || !token) {
                            const isMarketRedirect = new URL(window.location.href).searchParams.get('market')
                            if (isMarketRedirect) {
                                let url = process.env.REACT_APP_HE_URL
                                let redirectionLink = url + `${url.includes("staging") ? "&" : "?"}market=true`
                                window.location.replace(redirectionLink)
                                return;
                            }
                            window.location.replace(process.env.REACT_APP_HE_URL);
                            return;
                        }
                        await checkSubscriber(null);
                        return;
                    }
                    await checkSubscriber({ msisdn: cellNo, accessToken: token });
                    return;
                }

                const authResponse = await authentication(payload);
                await checkSubscriber(authResponse.data.data);
            } catch (error) {
                console.error("Authentication failed:", error);
                setTimeout(() => setLoading(false), SPLASH_TIMEOUT);
            }
        };

        init();
    }, []);

    const value = {
        loading,
        loadingState: setLoading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading ? children : <SplashScreen />}
        </AuthContext.Provider>
    );
};