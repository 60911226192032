import axios from "axios";
import urduMonthsConfig from "../configs/urdu.months.config.json";
import moment from "moment";
export const formatDateWithUrduMonth = (date) => {
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const [year, month, day] = formattedDate.split("-");
  const urduMonth = urduMonthsConfig[month];
  return `${day}-${urduMonth}-${year}`;
};

export const getVimeoVideoIdFromUrl = (url) => {
  const regex = /\/(\d+)\//;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const getVimeoThumbnail = async (videoId) => {
  // const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
  // const data = await response.json();
  // return data[0].thumbnail_large; // You can also use other sizes like thumbnail_small, thumbnail_medium, etc.
  return axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`)
    .then((response) => {
      let details = {
        thumbnail: response.data[0].thumbnail_large,
        time: response.data[0].duration
      }
      // console.log("RESPONSE", response.data[0])
      return details;
    })
    .catch((error) => {
      // console.log("ERROR", error);
    });
};

export function isSowingDateInRange(startDate, endDate, sowingDate) {
  // Convert the input dates to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);
  const sowingDt = new Date(sowingDate);

  // Check if the currentDate lies between startDate and endDate
  return sowingDt >= start && sowingDt <= end;
}

const createPunjabiMonths = (year) => [
  { name: 'Chet', start: new Date(year, 2, 15), end: new Date(year, 3, 14) },
  { name: 'Vaisakh', start: new Date(year, 3, 15), end: new Date(year, 4, 14) },
  { name: 'Jeth', start: new Date(year, 4, 15), end: new Date(year, 5, 14) },
  { name: 'Harh', start: new Date(year, 5, 15), end: new Date(year, 6, 14) },
  { name: 'Sawan', start: new Date(year, 6, 15), end: new Date(year, 7, 14) },
  { name: 'Bhadon', start: new Date(year, 7, 15), end: new Date(year, 8, 14) },
  { name: 'Asu', start: new Date(year, 8, 15), end: new Date(year, 9, 14) },
  { name: 'Katak', start: new Date(year, 9, 15), end: new Date(year, 10, 14) },
  { name: 'Maghar', start: new Date(year, 10, 15), end: new Date(year, 11, 14) },
  { name: 'Poh', start: new Date(year - 1, 11, 15), end: new Date(year, 0, 14) }, // For January month 1 to 14 
  { name: 'Poh', start: new Date(year, 11, 15), end: new Date(year + 1, 0, 14) }, // For December month 15 to 31
  { name: 'Magh', start: new Date(year, 0, 15), end: new Date(year, 1, 14) },
  { name: 'Phagun', start: new Date(year, 1, 15), end: new Date(year, 2, 14) },
];

const stripTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export function getPunjabiDate() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  // Generate Punjabi months dynamically for the current year
  const punjabiMonths = createPunjabiMonths(currentYear);

  const currentDateStripped = stripTime(currentDate);

  for (const month of punjabiMonths) {
    const monthStartStripped = stripTime(month.start);
    const monthEndStripped = stripTime(month.end);

    if (currentDateStripped >= monthStartStripped && currentDateStripped <= monthEndStripped) {
      const punjabiDay = Math.floor((currentDate - month.start) / (1000 * 60 * 60 * 24)) + 1;
      return `${punjabiDay}`;
    }
  }

  return 'Date not found in Punjabi calendar range';
}


export const findWeatherForCurrentHour = (data) => {
  const currentHour = moment().startOf('hour');
  const result = data?.find(item => moment(item.DateTime).isSame(currentHour, 'hour'));
  return result ? result : data[0] || "Weather data not found for the current hour";
};

export const findTodayWeather = (data) => {
  const currentDay = moment().startOf('day');

  const result = data?.find(item => {
    const itemDate = moment(item.DateTime);
    return itemDate.isSame(currentDay, 'day');
  });

  return result || "Weather data not found for the current day";
};

// =============================== | Format Price String (Using in Mandi Rates Data table) | =============================== //
export const formatPriceStr = (price) => {
  return (
    <div className='flex justify-center items-center gap-1'>
      <p>روپے</p>
      <p className='digit mt-2'>{price}</p>
    </div>
  )
}
export function formatUrduStringToSlug(input) {
  return input
    // .replace(/[^آ-ی0-9\s]/g, '') // Remove non-urdu characters except spaces
    .trim()                  // Remove leading and trailing whitespace
    .replace(/\s+/g, '-');   // Replace spaces with hyphens
}

export function formatStringToSlug(input) {
  return input
    .toLowerCase()           // Convert the string to lowercase
    .replace(/[^a-z0-9\s]/g, '') // Remove non-alphanumeric characters except spaces
    .trim()                  // Remove leading and trailing whitespace
    .replace(/\s+/g, '-');   // Replace spaces with hyphens
}

export function slugToOriginalFormat(slug) {
  return slug
    .replace(/-/g, ' ')      // Replace hyphens with spaces
    .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize the first letter of each word
    .trim();                 // Remove leading and trailing whitespace
}

export function shuffleArray(array) {
  // Create a copy of the original array to avoid mutating it
  let shuffledArray = array.slice();

  // Fisher-Yates (Knuth) Shuffle Algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i (inclusive)
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements at indices i and j
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
}

export function isEnglishLanguage(input) {
    // Regular expression to match English characters, spaces, and basic punctuation
    const englishRegex = /^[a-zA-Z0-9\s.,'";:!?()-]*$/;
    return englishRegex.test(input);
}

// Stock validation
export function validateStock(cartItems) {
  // Create a map to track total quantities per variant
  const variantQuantities = new Map();

  // Sum up quantities for each variant
  for (const item of cartItems) {
    const variantId = item.variants.id;
    const currentQuantity = variantQuantities.get(variantId) || 0;
    variantQuantities.set(variantId, currentQuantity + item.quantity);
  }

  // Check if any variant's total quantity exceeds its stock
  for (const item of cartItems) {
    const variantId = item.variants.id;
    const totalQuantity = variantQuantities.get(variantId);
    const availableStock = item.variants.stock;

    if (totalQuantity > availableStock) {
      return false;
    }
  }

  return true;
}