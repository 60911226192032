import React from "react"
import Spinner from "react-bootstrap/Spinner";

const SpinnerComponent = () => {
  return (
    <Spinner style={{ position: "absolute", top: "48%", left: "46%" }} animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  )
}

export default SpinnerComponent;