import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// =================== | Get Categories | ===================
export const getCategories = createAsyncThunk(
    "MarketPlace/getCategories",
    async () => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(`${baseURL}/categories`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("CATEGORIES - RESPONSE:::::::::::::::::", response.data.data);
            return response.data.data;
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Get Brands | ===================
export const getBrands = createAsyncThunk(
    "MarketPlace/getBrands",
    async () => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(`${baseURL}/brands`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("BRANDS - RESPONSE:::::::::::::::::", response.data.data);
            return response.data.data;
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Get Products with discount for today | ===================
export const getTodayOffers = createAsyncThunk(
    "MarketPlace/getTodayOffers",
    async ({ category = '', page, limit, type = 'main' }) => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(`${baseURL}/products/today-offers?${category && `category=${category}&`}page=${page}&limit=${limit}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("TODAY OFFERS - RESPONSE:::::::::::::::::", response.data.data);
            return {
                type,
                products: response.data.data.products,
            }
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Get Area wise Popular Products | ===================
export const getPopularProducts = createAsyncThunk(
    "MarketPlace/getPopularProducts",
    async ({ page, limit, city, cityUrdu, category = '', type = 'main' }) => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(`${baseURL}/products/popular?page=${page}&limit=${limit}${category && `&category=${category}`}&city=${city}&cityUrdu=${cityUrdu}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("POPULAR PRODUCTS - RESPONSE:::::::::::::::::", response.data.data);
            return {
                type,
                products: response.data.data.products,
            };
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Get Best Selling Products | ===================
export const getBestSellingProducts = createAsyncThunk(
    "MarketPlace/getBestSellingProducts",
    async ({ page, limit, category = '', type = 'main' }) => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(`${baseURL}/products/best-selling?page=${page}&limit=${limit}${category && `&category=${category}`}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("BEST SELLING PRODUCTS - RESPONSE:::::::::::::::::", response.data.data);
            return {
                type,
                products: response.data.data.products,
            }
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Search Products | ===================
export const searchProducts = createAsyncThunk(
    "MarketPlace/searchProducts",
    async ({ page, limit, value = '', category = '', brand = '', sortby = '', type = 'main' }) => {
        try {
            const token = sessionStorage.getItem("authToken");
            // Create an object with all possible query parameters
            const queryParams = {
                page,
                limit,
                value,
                category,
                brand,
                sortby
            };

            // Filter out empty parameters and convert to URLSearchParams
            const params = new URLSearchParams(
                Object.entries(queryParams)
                    .filter(([_, value]) => value !== '')
                    .reduce((acc, [key, value]) => ({
                        ...acc,
                        [key]: value
                    }), {})
            );

            const response = await axios.get(`${baseURL}/products/search?${params}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("SEARCH PRODUCTS - RESPONSE:::::::::::::::::", response.data.data);
            return {
                type,
                products: response.data.data.products,
            };
        } catch (error) {
            console.log("ERROR", error);
            return error.response.data || error.message;
        }
    }
);

// =================== | Add To Cart | ===================
export const addToCart = createAsyncThunk(
    "MarketPlace/addToCart",
    async (data) => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.post(`${baseURL}/cart`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("ADD TO CART - RESPONSE:::::::::::::::::", response.data.data);
            return response.data;
        } catch (error) {
            console.log("ERROR", error);
            return error.response.data || error.message;
        }
    }
);

// =================== | Get Cart Items | ===================
export const getCartItems = createAsyncThunk(
    "MarketPlace/getCartItems",
    async () => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(`${baseURL}/cart`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("CART ITEMS - RESPONSE:::::::::::::::::", response.data.data);
            return response.data.data;
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Remove Cart Item | ===================
export const removeCartItem = createAsyncThunk(
    "MarketPlace/removeCartItem",
    async (id) => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.delete(`${baseURL}/cart/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("REMOVE CART ITEM - RESPONSE:::::::::::::::::", response.data.data);
            return {
                id,
                response: response.data
            };
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Empty Cart | ===================
export const emptyCart = createAsyncThunk(
    "MarketPlace/emptyCart",
    async () => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.delete(`${baseURL}/cart/empty`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("EMPTY CART - RESPONSE:::::::::::::::::", response.data);
            return response.data;
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Update Cart Item | ===================
export const updateCartItem = createAsyncThunk(
    "MarketPlace/updateCartItem",
    async (data) => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.patch(`${baseURL}/cart/${data.id}`, {
                quantity: data.quantity
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("UPDATE CART ITEM - RESPONSE:::::::::::::::::", response.data);
            return response.data;
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

// =================== | Create Order | ===================
export const createOrder = createAsyncThunk(
    "MarketPlace/createOrder",
    async (data, { rejectWithValue }) => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.post(`${baseURL}/order`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("CREATE ORDER - RESPONSE:::::::::::::::::", response.data);
            return response.data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.response.data || error.message);
        }
    }
);

// =================== | Get Orders | ===================
export const getOrders = createAsyncThunk(
    "MarketPlace/getOrders",
    async () => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(`${baseURL}/order/subscriber`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // console.log("ORDERS - RESPONSE:::::::::::::::::", response.data.data);
            return response.data.data;
        } catch (error) {
            console.log("ERROR", error);
        }
    }
);

export const MarketPlaceSlice = createSlice({
    name: "MarketPlace",
    initialState: {
        // ==================================================================================== //
        // ================================= | Data from DB | ================================= //
        // ==================================================================================== //
        categories: [], // ================ All categories from DB
        brands: [], // ======================= All brands from DB
        todayOfferProducts: null, // ========= For today offers page
        mainTodayOfferProducts: null, // ===== For main page
        popularProducts: null, // ============ For suggested products page
        mainPopularProducts: null, // ======== For main page
        bestSellerProducts: null, // ========= For best selling products page
        mainBestSellerProducts: null, // ===== For main page
        searchedProducts: null, // =========== For search page
        mainSearchProducts: null, // ========= For main page
        selectedCategory: null, // ======== Selected category
        cartItems: [], // ================= Cart items
        orders: [], // ==================== Orders
        // ==================================================================================== //
        // =================================== | loading | ==================================== //
        // ==================================================================================== //
        isCategoriesLoading: false,
        isBrandsLoading: false,
        isTodayOffersLoading: false,
        isPopularProductsLoading: false,
        isBestSellingProductsLoading: false,
        isSearchedProductsLoading: false,
        isCartItemsLoading: false,
        isOrdersLoading: false,
        // ==================================================================================== //
        // ================================== | selections | ================================== //
        // ==================================================================================== //
        filterValue: "",
        cartDrawerProduct: null,
        consolidateCartItems: [],
        selectedCity: null,
        // ==================================================================================== //
        // ==================================== | Modals | ==================================== //
        // ==================================================================================== //
        isCityModal: false,
        notSignedInModal: false,
        // ==================================================================================== //
        // ==================================== | Drawer | ==================================== //
        // ==================================================================================== //
        filterDrawer: false,
        cartDrawer: false,
    },
    reducers: {
        // ==================================================================================== //
        // ================================= | Data from DB | ================================= //
        // ==================================================================================== //
        setCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        updateCartItemQuantity: (state, action) => {
            const index = state.cartItems.findIndex((item) => item.id === action.payload.id);
            if (index > -1) {
                if (action.payload.type === "plus") {
                    state.cartItems[index].quantity += 1;
                    return;
                }
                if (state.cartItems[index].quantity > 1) state.cartItems[index].quantity -= 1;
            }
        },
        setOrders: (state, action) => {
            state.orders = [...(state.orders || []), action.payload];
        },
        // ==================================================================================== //
        // ================================== | selections | ================================== //
        // ==================================================================================== //
        setFilterValue: (state, action) => {
            state.filterValue = action.payload;
        },
        setCartDrawerProduct: (state, action) => {
            state.cartDrawerProduct = action.payload;
        },
        setConsolidateCartItems: (state, action) => {
            state.consolidateCartItems = action.payload;
        },
        setCity: (state, action) => {
            state.selectedCity = action.payload;
        },
        // ==================================================================================== //
        // ==================================== | Modals | ==================================== //
        // ==================================================================================== //
        setCityModal: (state, action) => {
            state.isCityModal = action.payload;
        },
        setNotSignedInModal: (state, action) => {
            state.notSignedInModal = action.payload;
        },
        // ==================================================================================== //
        // ==================================== | Drawer | ==================================== //
        // ==================================================================================== //
        setFilterDrawer: (state, action) => {
            state.filterDrawer = action.payload;
        },
        setCartDrawer: (state, action) => {
            state.cartDrawer = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // ==================================================================================== //
            // ================================== | Categories | ================================== //
            // ==================================================================================== //
            .addCase(getCategories.pending, (state) => {
                state.isCategoriesLoading = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
                state.isCategoriesLoading = false;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.isCategoriesLoading = false;
            })
            // ==================================================================================== //
            // ==================================== | Brands | ==================================== //
            // ==================================================================================== //
            .addCase(getBrands.pending, (state) => {
                state.isBrandsLoading = true;
            })
            .addCase(getBrands.fulfilled, (state, action) => {
                state.brands = action.payload;
                state.isBrandsLoading = false;
            })
            .addCase(getBrands.rejected, (state, action) => {
                state.isBrandsLoading = false;
            })
            // ==================================================================================== //
            // ================================= | Today Offers | ================================= //
            // ==================================================================================== //
            .addCase(getTodayOffers.pending, (state) => {
                state.isTodayOffersLoading = true;
            })
            .addCase(getTodayOffers.fulfilled, (state, action) => {
                if (action.payload.type === 'main') {
                    state.mainTodayOfferProducts = action.payload.products;
                } else {
                    state.todayOfferProducts = action.payload.products;
                }
                state.isTodayOffersLoading = false;
            })
            .addCase(getTodayOffers.rejected, (state, action) => {
                state.isTodayOffersLoading = false;
            })
            // ==================================================================================== //
            // =============================== | Popular Products | =============================== //
            // ==================================================================================== //
            .addCase(getPopularProducts.pending, (state) => {
                state.isPopularProductsLoading = true;
            })
            .addCase(getPopularProducts.fulfilled, (state, action) => {
                if (action.payload.type === 'main') {
                    state.mainPopularProducts = action.payload.products;
                } else {
                    state.popularProducts = action.payload.products;
                }
                state.isPopularProductsLoading = false;
            })
            .addCase(getPopularProducts.rejected, (state, action) => {
                state.isPopularProductsLoading = false;
            })
            // ==================================================================================== //
            // ============================ | Best Selling Products | ============================= //
            // ==================================================================================== //
            .addCase(getBestSellingProducts.pending, (state) => {
                state.isBestSellingProductsLoading = true;
            })
            .addCase(getBestSellingProducts.fulfilled, (state, action) => {
                if (action.payload.type === 'main') {
                    state.mainBestSellerProducts = action.payload.products;
                } else {
                    state.bestSellerProducts = action.payload.products;
                }
                state.isBestSellingProductsLoading = false;
            })
            .addCase(getBestSellingProducts.rejected, (state, action) => {
                state.isBestSellingProductsLoading = false;
            })
            // ==================================================================================== //
            // =============================== | Search Products | ================================ //
            // ==================================================================================== //
            .addCase(searchProducts.pending, (state) => {
                state.isSearchedProductsLoading = true;
            })
            .addCase(searchProducts.fulfilled, (state, action) => {
                if (action.payload.type === 'main') state.mainSearchProducts = action.payload.products;
                else state.searchedProducts = action.payload.products;
                state.isSearchedProductsLoading = false;
            })
            .addCase(searchProducts.rejected, (state, action) => {
                state.isSearchedProductsLoading = false;
            })
            // ==================================================================================== //
            // ================================== | Add To Cart | ================================== //
            // ==================================================================================== //
            .addCase(addToCart.pending, (state) => {
                state.isCartItemsLoading = true;
            })
            .addCase(addToCart.fulfilled, (state, action) => {
                // state.cartItems = [...(state.cartItems || []), action.payload];
                state.isCartItemsLoading = false;
            })
            .addCase(addToCart.rejected, (state, action) => {
                state.isCartItemsLoading = false;
            })
            // ==================================================================================== //
            // =============================== | Get Cart Items | ================================ //
            // ==================================================================================== //
            .addCase(getCartItems.pending, (state) => {
                state.isCartItemsLoading = true;
            })
            .addCase(getCartItems.fulfilled, (state, action) => {
                state.cartItems = action.payload;
                state.isCartItemsLoading = false;
            })
            .addCase(getCartItems.rejected, (state, action) => {
                state.isCartItemsLoading = false;
            })
            // ==================================================================================== //
            // =============================== | Remove Cart Item | =============================== //
            // ==================================================================================== //
            .addCase(removeCartItem.pending, (state) => {
                state.isCartItemsLoading = true;
            })
            .addCase(removeCartItem.fulfilled, (state, action) => {
                state.cartItems = state.cartItems.filter((item) => item.id !== action.payload.id);
                state.isCartItemsLoading = false;
            })
            .addCase(removeCartItem.rejected, (state, action) => {
                state.isCartItemsLoading = false;
            })
            // ==================================================================================== //
            // ================================= | Empty Cart | ================================== //
            // ==================================================================================== //
            .addCase(emptyCart.pending, (state) => {
                state.isCartItemsLoading = true;
            })
            .addCase(emptyCart.fulfilled, (state, action) => {
                state.cartItems = [];
                state.isCartItemsLoading = false;
            })
            .addCase(emptyCart.rejected, (state, action) => {
                state.isCartItemsLoading = false;
            })
            // ==================================================================================== //
            // ================================= | Create Order | ================================= //
            // ==================================================================================== //
            .addCase(createOrder.pending, (state) => {
                state.isOrdersLoading = true;
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                // state.orders = [...(state.orders || []), action.payload];
                // state.isOrdersLoading = false;
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.isOrdersLoading = false;
            })
            // ==================================================================================== //
            // ================================= | Get Orders | =================================== //
            // ==================================================================================== //
            .addCase(getOrders.pending, (state) => {
                state.isOrdersLoading = true;
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.orders = action.payload;
                state.isOrdersLoading = false;
            })
            .addCase(getOrders.rejected, (state, action) => {
                state.isOrdersLoading = false;
            });
    }
});

export const {
    // ==================================================================================== //
    // ===================================== | Data | ===================================== //
    // ==================================================================================== //
    setCategory,
    updateCartItemQuantity,
    setOrders,
    // ==================================================================================== //
    // ================================== | Selection | =================================== //
    // ==================================================================================== //
    setFilterValue,
    setCartDrawerProduct,
    setConsolidateCartItems,
    setCity,
    // ==================================================================================== //
    // ==================================== | Modals | ==================================== //
    // ==================================================================================== //
    setCityModal,
    setNotSignedInModal,
    // ==================================================================================== //
    // ==================================== | Drawer | ==================================== //
    // ==================================================================================== //
    setFilterDrawer,
    setCartDrawer,
} = MarketPlaceSlice.actions;

export default MarketPlaceSlice.reducer;
