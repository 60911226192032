// hooks/useRouteChecks.js
import { useMemo } from 'react';

const marketSubRoutes = [
    "/market/offers",
    "/market/category",
    "/market/brand",
    "/market/today-offers",
    "/market/suggested-products",
    "/market/best-selling",
    "/market/product",
    "/market/cart",
    "/market/checkout",
    "/market/order-placed",
    "/market/order-details"
];
export const useRouteChecks = (pathname) => {
    return useMemo(() => ({
        isProfileRoute: pathname.startsWith("/personal-info"),
        isCropDetailsRoute: pathname.startsWith("/crop-details/"),
        isSubscriptionConfirmationRoute: pathname.startsWith("/confirm-subscription"),
        isVerificationRoute: pathname.startsWith("/verification"),
        isSubscriptionRoute: pathname.startsWith("/subscription"),
        isYourCropsRoute: pathname.startsWith("/your-crops"),
        isMarketOffersRoute: marketSubRoutes.some((route) => pathname.startsWith(route)),
        isProductRoute: pathname.startsWith("/market/product"),
        isCartRoute: pathname.startsWith("/market/cart"),
        isCheckoutRoute: pathname.startsWith("/market/checkout"),
        isOrderPlacedRoute: pathname.startsWith("/market/order-placed"),
        isOrderDetailsRoute: pathname.startsWith("/market/order-details"),
        isMyOrdersRoute: pathname.startsWith("/market/my-orders"),
        isErrorRoute: pathname.startsWith("/error-page"),
        isVideoPlayerRoute: pathname.startsWith("/video-player"),
        isRootRoute: pathname === "/"
    }), [pathname]);
};